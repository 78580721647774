import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable( {
	providedIn: 'root'
} )

export class TokenService {

	private authToken: string = '';
	private launchSystem = environment.launchSystem;

	public getAuthToken (): string {
		return this.authToken;
	}

	public setAuthToken ( authToken: string ): void {
		this.authToken = authToken;
	}

	public getLaunchSystem (): string {
		return this.launchSystem;
	}

}
